import {
  IonButtons,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonBackButton,
} from "@ionic/react";
import React from "react";
import { useStore } from "react-redux";
import { Md5 } from "md5-typescript";
import { useParams } from "react-router";
import "./Header.scss";
import "./Page.scss";
import shortid from "shortid";
import NullView from "./views/NullView";
import HomeView from "./views/HomeView";
import PremiumView from "./views/PremiumView";
import FormationsView from "./views/FormationsView";
import VideosView from "./views/VideosView";
import VideoView from "./views/VideoView";
import RecipesView from "./views/RecipesView";
import RecipePublishView from "./views/RecipePublishView";
import AccountView from "./views/AccountView";
import InfosView from "./views/InfosView";
import PasswordView from "./views/PasswordView";
import NotificationsView from "./views/NotificationsView";
import CgurgpdView from "./views/CgurgpdView";
import SOSView from "./views/SOSView";
import HelpView from "./views/HelpView";
import AboutView from "./views/AboutView";

const Page: React.FC = () => {
  const { name } = useParams<{ name: string }>();
  const state = useStore().getState();
  const gravatar = "https://gravatar.com/avatar/" + Md5.init(state.email);

  const componentLoader = (name: string) => {
    switch (name) {
      case "Home":
        return <HomeView key={shortid.generate()}></HomeView>;
      case "Premium":
        return <PremiumView key={shortid.generate()}></PremiumView>;
      case "Formations":
        return <FormationsView key={shortid.generate()}></FormationsView>;
      case "Videos":
        return <VideosView key={shortid.generate()}></VideosView>;
      case "Video":
        return <VideoView key={shortid.generate()}></VideoView>;
      case "Recipes":
        return <RecipesView key={shortid.generate()}></RecipesView>;
      case "RecipePublish":
        return <RecipePublishView key={shortid.generate()}></RecipePublishView>;
      case "Account":
        return <AccountView key={shortid.generate()}></AccountView>;
      case "Infos":
        return <InfosView key={shortid.generate()}></InfosView>;
      case "Password":
        return <PasswordView key={shortid.generate()}></PasswordView>;
      case "Notifications":
        return <NotificationsView key={shortid.generate()}></NotificationsView>;
      case "Cgurgpd":
        return <CgurgpdView key={shortid.generate()}></CgurgpdView>;
      case "SOS":
        return <SOSView key={shortid.generate()}></SOSView>;
      case "Help":
        return <HelpView key={shortid.generate()}></HelpView>;
      case "About":
        return <AboutView key={shortid.generate()}></AboutView>;
      default:
        return <NullView key={shortid.generate()}></NullView>;
    }
  };
  const ContentComponent = componentLoader(name);
  const getTitle = (name: string) => {
    switch (name) {
      case "Home":
        return "Accueil";
      case "Premium":
        return "Devenir premium";
      case "Formations":
        return "Tutoriels vidéos";
      case "Videos":
        return "Tutoriels vidéos";
      case "Video":
        return "Tutoriel vidéo";
      case "Recipes":
        return "Recettes";
      case "RecipePublish":
        return "Publication de recette";
      case "Account":
        return "Mon compte";
      case "Infos":
        return "Mes informations";
      case "Password":
        return "Changer mon mot de passe";
      case "Notifications":
        return "Notifications";
      case "Cgurgpd":
        return "CGU et RGPD";
      case "SOS":
        return "Communauté";
      case "Help":
        return "Aide";
      case "About":
        return "À propos de Formaceo";
      default:
        return name;
    }
  };

  const title = getTitle(name);

  const getNav = (name: string) => {
    switch (name) {
      case "Video":
        return <IonBackButton defaultHref="/page/Videos" />;
      case "Infos":
      case "Password":
      case "Cgurgpd":
        return <IonBackButton defaultHref="/page/Account" />;
      case "RecipePublish":
        return <IonBackButton defaultHref="/page/Recipes" />;
      case "Premium":
      case "Notifications":
        return <IonBackButton defaultHref="/page/Home" />;
      default:
        return <IonMenuButton />;
    }
  };

  const nav = getNav(name);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">{nav}</IonButtons>

          <IonTitle>
            <div className="ion-text-center">{title}</div>
          </IonTitle>

          {/* <IonAvatar className="menu-avatar" slot="end">
            <IonImg src={gravatar} />
          </IonAvatar> */}

          {/* <IonButtons slot="primary">
            <IonButton>
              <IonIcon slot="icon-only" icon={notifications}></IonIcon>
            </IonButton>
          </IonButtons> */}
        </IonToolbar>
      </IonHeader>
      {ContentComponent}
    </IonPage>
  );
};

export default Page;
