import React from "react";

const ExploreContainer: React.FC = () => {
  return (
    <div>
      <h3>Les engagements de Formaceo :</h3>
      <br />
      FORMACEO SAS se tient garant à l’intégrité de vos données personnelles
      ainsi qu’au respect de vos droits.
      <br />
      FORMACEO SAS vous assure un niveau élevé de protection de vos données
      personnelles conformément au Règlement européen 2016/679 et la loi
      informatique et liberté n°78-17.
      <br />
      <br />
      Conformément aux dispositions de l’article 5 du Règlement européen
      2016/679, la collecte et le traitement des données des utilisateurs du
      site respectent les principes suivants :<br />
      <br />
      <h5>Licéité, loyauté et transparence</h5> les données ne peuvent être
      collectées et traitées qu’avec le consentement de l’utilisateur
      propriétaire des données. A chaque fois que des données à caractère
      personnel seront collectées, il sera indiqué à l’utilisateur que ses
      données sont collectées, et pour quelles raisons ses données sont
      collectées ;<br />
      <br />
      <h5>Finalités limitées</h5> La collecte et le traitement des données sont
      exécutés pour répondre à un ou plusieurs objectifs déterminés dans les
      présentes conditions générales d’utilisation ;<br />
      Minimisation de la collecte et du traitement des données : seules les
      données nécessaires à la bonne exécution des objectifs poursuivis par le
      site sont collectées ;<br />
      <br />
      <h5>Conservation des données réduites dans le temps</h5>
      Les données sont conservées pour une durée limitée, dont l’utilisateur est
      informé. Si la durée de conservation ne peut être communiquée à
      l’utilisateur ;<br />
      Intégrité et confidentialité des données collectées et traitées : le
      responsable du traitement des données s’engage à garantir l’intégrité et
      la confidentialité des données collectées.
      <br />
      <br />
      Afin d’être licites, et ce conformément aux exigences de l’article 6 du
      règlement européen 2016/679, la collecte et le traitement des données à
      caractère personnel ne pourront intervenir que s’ils respectent au moins
      l’une des conditions ci-après énumérées :<br />
      L’utilisateur a expressément consenti au traitement ;<br />
      Le traitement est nécessaire à la bonne exécution d’un contrat ;<br />
      <br />
      Le traitement répond à une obligation légale ;<br />
      Le traitement s’explique par une nécessité liée à la sauvegarde des
      intérêts vitaux de la personne concernée ou d’une autre personne physique
      ;<br />
      <br />
      Le traitement peut s’expliquer par une nécessité liée à l’exécution d’une
      mission d’intérêt public ou qui relève de l’exercice de l’autorité
      publique ;<br />
      <br />
      Le traitement et la collecte des données à caractère personnel sont
      nécessaires aux fins des intérêts légitimes et privés poursuivis par le
      responsable du traitement ou par un tiers.
      <br />
      <br />
      Nous restons à votre disposition pour toute question directement par mail
      à l’adresse suivante : contact@formaceo.com
      <br />
      <br />
      La présente version de la politique des données personnelles pourra être
      modifiée par FORMACEO SAS et sera accessible dans sa version modifiée sur
      le site internet de FORMACEO SAS www.formaceo.com
      <br />
      Les données personnelles collectées sur www.formaceo.com et/ou par
      téléphone et l’application Formaceo dans le cadre de vos appels et
      échanges avec nos formateurs sont traitées par FORMACEO SAS, domicilié au
      6 rue Vauban, 33024 bordeaux cedex- BP 70022, 882 488 190
      <br />
      <br />
      Formaceo SAS, responsable de traitement :<br />
      Les données personnelles collectées sur contact@formaceo.com ou par
      téléphone dans le cadre de nos appels et échanges avec nos salariés sont
      traitées par FORMACEO SAS 6 rue Vauban, 33024 bordeaux cedex- BP 70022,
      882 488 190.
      <br />
      <br />
      La collecte de vos données personnelles par Formaceo :<br />
      <br />
      Vos données personnelles sont collectées soit lors de votre visite et
      action sur www.formaceo.com, soit dans le cadre de nos appels
      téléphoniques, soit lors de votre visite sur notre application, soit lors
      du suivi de vos formations.
      <br />
      <br />
      Le site dispose d’un certificat SSL afin de garantir que les informations
      et le transfert des données transitant par le site sont sécurisés.
      <br />
      <br />
      Un certificat SSL (« Secure Socket Layer » Certificate) a pour but de
      sécuriser les données échangées entre l’utilisateur et le site.
      <br />
      <br />
      Nous ne collectons aucune donnée non-nécessaire à la finalité de
      traitement mentionné lors de la collecte.
      <br />
      <br />
      DONNÉES PERSONNELLES DES PERSONNES MINEURES
      <br />
      Conformément aux dispositions de l’article 8 du règlement européen
      2016/679 et à la loi Informatique et Libertés, seuls les mineurs âgés de
      15 ans ou plus peuvent consentir au traitement de leurs données
      personnelles.
      <br />
      <br />
      Si l’utilisateur est un mineur de moins de 15 ans, l’accord d’un
      représentant légal sera requis afin que des données à caractère personnel
      puissent être collectées et traitées.
      <br />
      L’éditeur du site se réserve le droit de vérifier par tout moyen que
      l’utilisateur est âgé de plus de 15 ans, ou qu’il aura obtenu l’accord
      d’un représentant légal avant de naviguer sur le site.
      <br />
      <br />
      Flux de données à caractère personnel en provenance et à destination en
      dehors de l’Union Européenne
      <br />
      Selon le règlement de l’Union Européenne 2026/679 du parlement européen et
      du conseil : <br />
      Les flux de données à caractère personnel à destination et en provenance
      de pays en dehors de l'Union et d'organisations internationales sont
      nécessaires au développement du commerce international et de la
      coopération internationale.
      <br />
      L'augmentation de ces flux a créé de nouveaux enjeux et de nouvelles
      préoccupations en ce qui concerne la protection des données à caractère
      personnel. <br />
      <br />
      Cependant, il importe que, lorsque des données à caractère personnel sont
      transférées de l'Union à des responsables du traitement, sous-traitants ou
      autres destinataires dans des pays tiers ou à des organisations
      internationales, le niveau de protection des personnes physiques garanti
      dans l'Union par le présent règlement ne soit pas compromis, y compris en
      cas de transferts ultérieurs de données à caractère personnel au départ du
      pays tiers ou de l'organisation internationale à des responsables du
      traitement ou sous-traitants dans le même pays tiers ou dans un pays tiers
      différent, ou à une autre organisation internationale. En tout état de
      cause, les transferts vers des pays tiers et à des organisations
      internationales ne peuvent avoir lieu que dans le plein respect du présent
      règlement. Un transfert ne pourrait avoir lieu que si, sous réserve des
      autres dispositions du présent règlement, les dispositions du présent
      règlement relatives au transfert de données à caractère personnel vers des
      pays tiers ou à des organisations internationales sont respectées par le
      responsable du traitement ou le sous-traitant.
      <br />
      <br />
      La collecte de certaines données peut être obligatoire ou facultative :
      seules sont obligatoires les données affectées d’un astérisque, ou
      empêchant de générer le formulaire ou indiquées comme telles par les
      formateurs lors des échanges téléphoniques. Les données personnelles
      collectées par téléphone peuvent être collectées par les salariés de
      FORMACEO SAS, lesquels s’engagent à être en conformité avec la
      réglementation européenne et nationale sur les données personnelles.
      <br />
      <br />
      Nous sommes amenés potentiellement au traitement de vos données
      personnelles suivantes :<br />
      Les données relatives à votre identité et données de contact : civilités,
      nom, prénom, adresse de domicile, de livraison et de facturation, numéro
      de téléphone, email ;<br />
      Les éléments relatifs à votre navigation sur notre Site et application
      FORMACEO : identifiants, mot de passe, adresse IP, votre parcours de
      navigation, cookies ;<br />
      Les données relatives à vos souscriptions de formations : formation
      souscrite, données de livraison en cas de colis pour les supports de
      formation, historiques client, contrats signés ;<br />
      <br />
      Les données relatives au suivi des formations : identifiants de connexion
      e-learning, suivi de votre parcours de formation (notes, appréciations) ;
      <br />
      Des données relatives à votre parcours professionnel et étudiant
      (carrière, stage, niveau de d’étude )<br />
      Les autres données : localisation.
      <br />
      <br />
      Nos traitements de vos données personnelles :<br />
      <br />
      FORMACEO SAS est amené à traiter vos données personnelles pour des
      finalités décrites dans le cadre de nos formulaires de collecte via notre
      site www.formaceo.com ou notre application ; vos données personnelles sont
      en outre collectées principalement pour les finalités suivantes :<br />
      Notre brochure de formation, ainsi que notre programme scolaire :<br />
      Si vous y avez consenti via notre formulaire de contact sur le site
      www.formaceo.com , nous sommes susceptibles d’utiliser vos données
      personnelles afin de vous faire parvenir nos brochures de formation que
      vous aurez sélectionné ainsi que le programme de formation et un accès
      gratuit et limité à notre application par mail, suite à un appel
      téléphonique de l’un de nos salariés.
      <br />
      Une fois votre formation souscrite sur www.formaceo.com, nous vous ferons
      parvenir votre contrat de formation, ainsi que votre identifiant en
      E-Learning, les ouvrages liés à votre formation et votre facture.
      <br />
      L’exécution de vos formations et le suivi de notre relation
      <br />
      Nous utilisons vos données personnelles afin :<br />
      D’établir le contrat entre FORMACEO SAS et vous,
      <br />
      Assurer l’exécution des formations : vous offrir un accès illimité pendant
      votre formation, à votre rythme, assurer notre suivi personnalisé pour
      répondre à vos problématiques, le niveau d’étude initial, envoyer le
      support livresque.
      <br />
      le suivi client vous concernant : contrats, comptabilité, comptes et
      historique client, enquêtes de satisfaction, sondages et statistiques, et
      performance de nos formations.
      <br />
      <br />
      Vos coordonnées bancaires
      <br />
      Vos coordonnées bancaires sont collectées par notre prestataire : Banque
      populaire lequel garantit l’entière confidentialité de vos données
      bancaires et ces coordonnées ne sont conservées par nos soins que pour le
      temps de durée légale de conservation.
      <br />
      Notre protection contre les tentatives frauduleuses
      <br />
      Les données personnelles collectées peuvent être utilisées pour lutter
      contre la fraude notamment sur les paiements ou prélèvements effectués
      sans le cadre de la souscription à nos Formations Formaceo. A ce titre,
      nos prestataires en sécurité de paiement peuvent être rendus destinataire
      de ces données.
      <br />
      <br />
      Statistiques client <br />
      Certaines données sont traitées à des fins statistiques, notamment pour
      évaluer et améliorer la performance de nos formations et services, ou
      encore effectuer des mesures d’audience sur notre site. Les données
      personnelles sont alors anonymisées pour cette finalité.
      <br />
      Plus généralement, nous sommes également amenés à traiter, collecter,
      conserver pendant la durée de votre formation (le cas échéant) vos données
      personnelles.
      <br />
      Les droits d’utilisateurs :<br />
      Conformément à la réglementation concernant le traitement des données à
      caractère personnel, l’utilisateur possède les droits ci-après énumérés.
      <br />
      Afin que le responsable du traitement des données fasse droit à sa
      demande, l’utilisateur est tenu de lui communiquer : prénom et nom ainsi
      que son adresse e-mail.
      <br />
      Le responsable du traitement des données est tenu de répondre à
      l’utilisateur dans un délai de 30 (trente) jours maximum.
      <br />
      <br />
      Le traitement de vos données personnelles par Formaceo :<br />
      Conformément à la réglementation, le traitement de vos données
      personnelles par Formaceo est fondé s’il repose sur l’un des fondements
      suivants :<br />
      Votre consentement au traitement de vos données par nos soins : vous
      acceptez le traitement de vos données personnelles par le biais d’un
      consentement exprès (case à cocher, clic « d’acceptation » ou de «
      validation » ….). Vous pouvez retirer ce consentement à tout moment ; ou
      <br />
      L’existence d’un accord entre Formaceo et vous : le traitement des données
      est alors justifié par les besoins de l’exécution du contrat (livraison,
      suivi des formations et apprentissage, paiements, facturation…) ; ou
      <br />
      Un intérêt légitime pour Formaceo au traitement de vos données dès lors
      que cet intérêt proportionné respecte vos droits fondamentaux et votre vie
      privée ; ou
      <br />
      La Loi ou la réglementation en vigueur lorsque celles-ci obligent Formaceo
      au traitement de vos données personnelles ; ou
      <br />
      Droit de ne pas faire l’objet d’une décision fondée exclusivement sur un
      procédé automatisé
      <br />
      Conformément aux dispositions du règlement 2016/679, l’utilisateur a le
      droit de ne pas faire l’objet d’une décision fondée exclusivement sur un
      procédé automatisé si la décision produit des effets juridiques le
      concernant, ou l’affecte de manière significative de façon similaire ; ou
      <br />
      Modalités et durées de conservation de vos données personnelles :<br />
      <br />
      Nous gérons vos données personnelles selon trois phases :<br />
      une phase active où les données sont conservées pour le temps indiqué
      ci-dessous en base « active » : vos données personnelles sont alors
      accessibles uniquement par les personnes ayant un besoin opérationnel à y
      accéder afin de réaliser les traitements autorisés
      <br />
      une phase d’archivage (pour un temps supplémentaire à la conservation en
      base « active ») lorsqu’un motif légitime le justifie : vos données
      personnelles sont alors archivées avec un accès restreint et pour une
      durée limitée.
      <br />
      Une phase de suppression ou d’anonymisation : à l’issue de l’archivage
      supplémentaire dans les délais ci-dessous, vos données personnelles sont
      supprimées ou anonymisées (de telle sorte qu’elles ne peuvent plus
      constituer une donnée personnelles vous identifiant).
      <br />
      Vos données personnelles sont conservées pour le temps nécessaires aux
      besoins de leur traitement, de notre relation client le cas échéant et
      l’exécution des contrats, ou dans les limites spécifiquement édictées
      réglementairement; <br />
      Nous sommes susceptibles de conserver vos données personnelles en
      archivage pour les besoins de conservation d’éléments justificatifs
      comptables, fiscaux ou probatoires le temps des prescriptions applicables.
      <br />
      Politique des cookies
      <br />
      Nous utilisons divers types de cookies sur le site www.formaceo.com pour
      lesquels votre consentement est sollicité ; sont utilisés les cookies
      suivants : cookies nécessaires à l’utilisation optimisée du site et
      cookies traceurs destinés à l’authentification auprès d’un service, y
      compris ceux visant à assurer la sécurité du mécanisme d’authentification,
      par exemple en limitant les tentatives d’accès robotisés ou inattendues ,
      cookies analytiques nous permettant de comprendre et d’analyser votre
      navigation sur notre site.
      <br />
      Un « cookie » est un fichier de petite taille (moins de 4 ko), stocké par
      le site sur le disque dur de l’utilisateur, contenant des informations
      relatives aux habitudes de navigation de l’utilisateur.
      <br />
      Ces fichiers lui permettent de traiter des statistiques et des
      informations sur le trafic, de faciliter la navigation et d’améliorer le
      service pour le confort de l’utilisateur.
      <br />
      Pour l’utilisation de fichiers « cookies » impliquant la sauvegarde et
      l’analyse de données à caractère personnel, le consentement de
      l’utilisateur est nécessairement demandé.
      <br />
      Ce consentement de l’utilisateur est considéré comme valide pour une durée
      de 13 (treize) mois maximum. A l’issue de cette période, le site demandera
      à nouveau l’autorisation de l’utilisateur pour enregistrer des fichiers «
      cookies » sur son disque dur.
      <br />
      <br />
      Dans le cas où l’utilisateur décide de désactiver les fichiers « cookies
      », il pourra poursuivre sa navigation sur le site. Toutefois, tout
      dysfonctionnement du site provoqué par cette manipulation ne pourrait être
      considéré comme étant du fait de l’éditeur du site.
      <br />
      Les cookies sont soumis à votre acceptation sur notre site
      www.formaceo.com lors de votre première visite. Vous pouvez également
      gérer votre acceptation ou refus de cookies directement depuis les
      paramètres de votre navigateur. Le refus de cookies peut perturber votre
      navigation sur notre site.
      <br />
      Rétractation de votre accord pour la collecte et/ou au traitement de vos
      données personnelles :<br />
      Votre consentement accordé pour la collecte de vos données personnelles
      peut être retiré en écrivant à FORMACEO SAS par email à www.formaceo.com,
      par courrier à l’adresse : FORMACEO SAS 6 rue Vauban, 33024 bordeaux
      cedex- BP 70022
      <br />
      en mentionnant vos nom, prénom, e-mail et adresse avec la nature et
      l’objet précis de votre demande de retrait.
      <br />
      <br />
      Vous pouvez également nous adresser tout commentaire sur vos données
      personnelles, Formaceo étant particulièrement vigilantquant au respect de
      vos droits.
      <br />
      Vous pouvez également directement revenir sur votre consentement à des
      cookies de tiers en paramétrant votre navigateur afin d’en empêcher la
      dépose.
      <br />
      <br />
      L’exercice de vos droits sur vos données personnelles
      <br />
      Vous disposez :<br />
      D’un droit d’accès vous permettant d’obtenir la confirmation que des
      données vous concernant sont ou ne sont pas traitées ou la communication
      d’une copie de l’ensemble des données personnelles détenues par FORMACEO
      SAS.
      <br />
      D’un droit de rectification vous permettant de faire rectifier une
      information vous concernant lorsque celle-ci est obsolète ou erronée. Il
      vous permet également de faire compléter des informations incomplètes vous
      concernant.
      <br />
      D’un droit d’effacement vous permettant – sous réserve de nos obligations
      légales de conservation – d’obtenir l’effacement de vos données
      personnelles.
      <br />
      D’un droit de limitation vous permettant de limiter les traitements de vos
      données en cas d’usage illicite de vos données, de besoins de disposer des
      données pour constater, exercer ou défendre vos droits, d’inexactitude de
      vos données. Vos données ne seront alors plus traitées activement le temps
      de l’exercice de votre droit de limitation.
      <br />
      D’un droit de portabilité de données vous permettant, sous réserves des
      conditions réglementaires, de récupérer vos données personnelles dans un
      format structuré ou solliciter leur transfert à un tiers.
      <br />
      D’un droit d’obtenir une intervention humaine vous permettant de demander
      quels ont été les critères déterminants de la décision auprès du Délégué à
      la Protection des Donnée concernant un traitement de vos données
      personnelles.
      <br />
      Droit de saisir l’autorité de contrôle compétente
      <br />
      Dans le cas où le responsable du traitement des données décide de ne pas
      répondre à la demande de l’utilisateur, et que l’utilisateur souhaite
      contester cette décision, ou, s’il pense qu’il est porté atteinte à l’un
      des droits énumérés ci-dessus, il est en droit de saisir la CNIL
      (Commission Nationale de l’Informatique et des Libertés,
      https://www.cnil.fr) ou tout juge compétent.
      <br />
      <br />
      DONNÉES PERSONNELLES DES PERSONNES MINEURES
      <br />
      Conformément aux dispositions de l’article 8 du règlement européen
      2016/679 et à la loi Informatique et Libertés, seuls les mineurs âgés de
      15 ans ou plus peuvent consentir au traitement de leurs données
      personnelles.
      <br />
      Si l’utilisateur est un mineur de moins de 15 ans, l’accord d’un
      représentant légal sera requis afin que des données à caractère personnel
      puissent être collectées et traitées.
      <br />
      L’éditeur du site se réserve le droit de vérifier par tout moyen que
      l’utilisateur est âgé de plus de 15 ans, ou qu’il aura obtenu l’accord
      d’un représentant légal avant de naviguer sur le site.
      <br />
      Droit de déterminer le sort des données après la mort
      <br />
      Il est rappelé à l’utilisateur qu’il peut organiser quel doit être le
      devenir de ses données collectées et traitées s’il décède, conformément à
      la loi n°2016-1321 du 7 octobre 2016.
      <br />
      <br />
      Vous pouvez exercer vos droits ci-dessus par courrier électronique :
      www.formaceo.com, par lettre à l’adresse suivante : FORMACEO SAS 6 rue
      Vauban, 33024 bordeaux cedex- BP 70022 en indiquant votre nom, prénom,
      domicile et e-mail (le cas échéant vos références client) ainsi que
      l’objet de votre demande dans des termes clairs et lisibles. Pour que
      Formaceo SAS s’assure que la demande émane bien de la personne concernée
      par les données personnelles, votre demande devra être accompagnée d’une
      copie d’une pièce d’identité. Formaceo SAS s’engage à donner suite à votre
      demande vérifiée sous un délai d’un mois à compter de sa réception.
      <br />
      En cas de difficulté, vous pouvez vous adresser directement à la
      Commission Nationale de l’Informatique et des Libertés (CNIL)
    </div>
  );
};

export default ExploreContainer;
