import {
  IonContent,
  IonRow,
  IonCol,
  IonIcon,
  IonList,
  IonImg,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonText,
  IonGrid,
  IonLoading,
  IonListHeader,
  IonCardSubtitle,
} from "@ionic/react";
import {
  chevronDownCircle,
  timeOutline,
  chevronDownOutline,
} from "ionicons/icons";
import React, { useEffect } from "react";
import { useStore } from "react-redux";
import axios from "axios";
import VideoCarroussel from "../../components/VideoCarroussel";

const Page: React.FC = () => {
  const state = useStore().getState();

  let endpoint = state.endpoint;
  interface StringMap {
    [key: string]: [];
  }

  let chier: StringMap = {};
  let [promiseResolved, setPromiseResolved] = React.useState(false);
  let [vidByTheme, setVidByTheme] = React.useState(chier);
  let videoCount = 0;
  let vids: any = [];
  let videoListUrl;
  let [countToDisplay, setCountToDisplay] = React.useState<number>(10);
  let [countTotal, setCountTotal] = React.useState<number>(1);

  if (!state.premium.boulangerie && !state.premium.patisserie) {
    // L'utilisateur n'a pas d'accès donc on utilise l'api free
    endpoint += "/listVideoFree";
    videoListUrl = "/Page/Formations";
  } else {
    endpoint +=
      "/handleVideoObject?boulangerie=" +
      state.premium.boulangerie +
      "&patisserie=" +
      state.premium.patisserie +
      "&user_aws_id=" +
      state.aws_id;
    videoListUrl = "/Page/Videos";
  }

  useEffect(() => {
    if (promiseResolved) {
      return;
    }
    setPromiseResolved(true);
    axios({
      method: "get",
      url: endpoint,
      headers: { "x-api-key": state.apiKey },
    }).then((response) => {
      let vidthemes: any = {};
      let urls: any = [];

      for (let vid of response.data) {
        if (urls[vid["thumb_url"] + vid.section]) {
          continue;
        }
        urls[vid["thumb_url"] + vid.section] = true;
        if (vidthemes[vid.section] == undefined) {
          vidthemes[vid.section] = [];
        }
        vidthemes[vid.section].push(vid);
      }
      setPromiseResolved(true);

      setVidByTheme(vidthemes);
      setCountTotal(response.data.length);

      return vidByTheme;
    });
  });

  let themes = [];
  if (promiseResolved) {
    let i = 0;
    for (let theme in vidByTheme) {
      if (i >= countToDisplay) {
        break;
      }
      const videlmt = vidByTheme[theme];
      themes.push(
        <VideoCarroussel
          videos={videlmt}
          key={theme}
          theme={theme}
        ></VideoCarroussel>
      );
      i++;
    }
  }

  if (promiseResolved) {
    const totalDisplayed = 10;
    let nbDisplayed = 0;
    for (let theme in vidByTheme) {
      const videlmt: any = vidByTheme[theme];
      let vid = videlmt[0];
      videoCount += Object.keys(videlmt).length;
      if (vid && nbDisplayed < totalDisplayed) {
        const url = vid.thumb_url;
        const title = vid.title;
        nbDisplayed++;
        vids.push(
          <IonCard key={theme} href={videoListUrl}>
            <IonImg src={url} />
            <IonCardHeader>
              <IonCardSubtitle>
                {theme} : {title}
              </IonCardSubtitle>
            </IonCardHeader>
          </IonCard>
        );
      }
    }
  }

  return (
    <IonContent
      class="page-videos page-footer"
      scrollEvents={true}
      onIonScrollEnd={(e) => {
        setCountToDisplay(countToDisplay + 10);
      }}
    >
      <IonGrid>
        <IonRow class="block-intro ion-align-items-center">
          <IonCol size="auto">
            <IonIcon color="primary" icon={chevronDownCircle}></IonIcon>
          </IonCol>
          <IonCol>
            <IonText className="text-md">
              Vous pouvez consulter plus de 200 vidéos d’apprentissage sur la
              boulangerie et patisserie.
            </IonText>
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol size="8">
            <div className="video-resume">
              <h5>Découvrez votre vidéo gratuite</h5>
              <IonCard>
                <IonCardHeader>
                  <video width="100%" controls>
                    <source
                      src="https://d350irnxbw74cr.cloudfront.net/test.mp4"
                      type="video/mp4"
                    ></source>
                  </video>
                  <IonCardContent>
                    La pâte à choux
                    <IonRow className="ion-align-items-center video-time">
                      <IonCol class="no-padding-left" size="auto">
                        <IonIcon color="medium" icon={timeOutline}></IonIcon>
                      </IonCol>
                      <IonCol class="no-padding-left">
                        <IonText color="medium">1:02</IonText>
                      </IonCol>
                    </IonRow>
                  </IonCardContent>
                  <IonList lines="full" mode="ios">
                    <IonListHeader>Dans la même catégorie</IonListHeader>
                  </IonList>
                  <IonList className="list-videos" lines="full" mode="ios">
                    <IonLoading
                      isOpen={!(countTotal > 1)}
                      message={"Chargement en cours..."}
                      spinner="dots"
                    />
                    {themes}
                  </IonList>
                </IonCardHeader>
              </IonCard>
            </div>
          </IonCol>
          <IonRow>
            <IonCol class="ion-padding-horizontal"></IonCol>
          </IonRow>
          <IonCol size="3">
            <IonRow>
              <IonCol>{vids}</IonCol>
            </IonRow>
          </IonCol>
        </IonRow>
      </IonGrid>

      {/* <div className="footer-sticky active ion-text-center">
        <IonRouterLink color="light" href="/page/Premium">Devenir Premium</IonRouterLink>
      </div> */}
    </IonContent>
  );
};

export default Page;
