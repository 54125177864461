import { IonText } from "@ionic/react";
import React from "react";

const ExploreContainer: React.FC = () => {
  return (
    <div>
      <h3>
        FORMACEO SAS, 6 RUE VAUBAN Boîte postale 70022- 33024 Bordeaux Cedex
      </h3>
      <br />
      Mail : contact@formaceo.com
      {/* <br />
      Tél : 07.69.50.09.52 */}
      <br />
      Site : www.formaceo.com
      <br />
      N° SIREN : 882 488 190
      <br />
      <br />
      <h4>LE SITE</h4>
      Le site www.formaceo.com est un espace d’information dédié au public pour
      les informer de nos activités y compris commerciale de l’éditeur et aussi
      sur ces produits et services.
      <br />
      Les conditions générales d’utilisations ont pour objet de définir
      l’ensemble des conditions et modalités pout les utilisateurs, de n’importe
      quel pays et à partir de n’importe quel outil informatique, mais aussi de
      répartir les droits et obligations mutuelles des parties dans ce cadre.
      <br />
      <br />
      <h4>L’ACCES A LA PLATEFORME</h4>
      <br />
      FORMACEO SAS ne sera pas responsable des possible difficultés d’accès au
      site, à l’application, de se connecter et ou autres privation et
      limitation indépendamment de sa volonté.
      <br />
      Le client s’avance à ne pas utiliser le site FORMACEO SAS ainsi que
      l’application et ces contenus à des fins commerciales, politiques,
      publicitaires et autres formes possibles de sollicitations.
      <br />
      FORMACEO SAS met à disposition l’accès à son site ainsi qu’à son
      application et le contenu 24/24H, 7/7 JOURS, 365 jours par an.
      <br />
      <br />
      FORMACEO SAS aura la possibilité de limiter, fermer et ou perturber une
      partie ou la totalité du site ainsi que l’accès, de supprimer des
      informations entrant en contraction avec les lois nationales et
      internationales, ou tout simplement afin de procéder à des mises à jour.
      <br />
      FORMACEO SAS a mis en place son site dans de parfaite conditions de
      sécurité informatique et d’utilisation, il ne sera pas responsable de
      toutes formes d’attaque informatique endurer par l’utilisateur lors ou non
      de sa navigation sur le site.
      <br />
      Toutes tentatives frauduleuses sur le site agissant contre son
      fonctionnement, ou l’introduction et ou modifications des données mis en
      place par FORMACEO SAS sont interdites et seront susceptibles d’entraîner
      des poursuites.
      <br />
      Toutes mise en place de votre part de liens hypertextes vers le site et
      application FORMACEO est strictement interdite sauf autorisation préalable
      et écrite à l’adresse suivante : contact@formaceo.com
      <br />
      FORMACEO SAS pourra décliner toutes propositions sans avoir à se
      justifier.
      <br />
      En cas d’autorisation par FORMACEO SAS, le ou les liens hypertextes ne
      seront qu temporaire et pourra être retirée à n’importe quel moment sans
      avoir à se justifier par FORMACEO SAS.
      <br />
      FORMACEO SAS décline toute responsabilité quant au contenu de d’autres
      sites via ces mêmes liens hypertextes.
      <br />
      <br />
      <h4>POLITIQUE DE CONFIDENTIALITE</h4>
      <br />
      Le règlement européen sur la protection des données vous garantit la
      sécurité de vos données personnelles.
      <br />
      FORMACEO SAS s’engage à traiter vos données personnelles conformément à la
      législation en vigueur.
      <br />
      <br />
      <h5>COLLECTE DE DONNEES</h5>
      La consultation du site www.formaceo.com ne récolte directement aucune
      donnée.
      <br />
      C’est l’utilisateur lui-même qui transmet les données que FORMACEO SAS est
      susceptible d’analyser via le formulaire contact afin d’améliorer
      l’utilisation et la visibilité sur le site www.formaceo.com
      <br />
      Ne sont demandées que les informations nécessaires au traitement de
      demande dans les meilleures conditions (Nom, Prénom, N° Téléphone, E-mail,
      ville).
      <br />
      Elles seront conservées durant les 24 mois de la formation.
      <br />
      Lors de votre inscription chez FORMACEO, vous fournissez un certain nombre
      de données : <br />
      Identité : NOM, PRENOM, ADRESSE, N° TELEPHONE, VILLE, E-MAIL...
      <br />
      Scolarité et professionnel : DIPLÔME, METIER...
      <br />
      Pendant votre formation, nous consignons votre mot de passe, l’évolution
      de votre formation, les messages, données de règlements, appréciation lors
      du suivi personnalisé.
      <br />
      Aucune donnée personnelle recueillie sur www.formaceo.com ne sera
      communiqué à des tiers sans son accord.
      <br />
      <br />
      <h5>FINALITE BASE DE DONNEES</h5>
      La récupération de vos données à caractère personnelle a pour but :<br />
      L’envoi de la brochure
      <br />
      De vous inscrire au sein d’une des formations de FORMACEO
      <br />
      Vous connecter à votre espace élève en ligne et profiter de votre
      plateforme personnalisée
      <br />
      Bénéficier du suivi de votre formation sur votre espace élève
      <br />
      D'utiliser tous les outils, modules comme l’évolution de vos quizz, les
      prises de rendez-vous, les Lives, les tutoriels vidéo ...
      <br />
      D’analyser vos données scolaires pour le suivi personnalisé
      <br />
      Réaliser des enquêtes occasionnelles de satisfaction
      <br />
      Réaliser des statistiques sur l’utilisation de nos pédagogiques
      <br />
      <IonText class="ion-padding-bottom">
        Le paiement de vos coûts de formation.
      </IonText>
    </div>
  );
};

export default ExploreContainer;
