/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:afafcca5-b254-4c2a-a0fc-003d2e0377ea",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_LMjZoLS31",
    "aws_user_pools_web_client_id": "4nnfqoftvg7tbo9vc3ubv3qo6j",
    "oauth": {},
    "aws_user_files_s3_bucket": "testapp3d89a5e41d2c42d79d8f39cab2cb74a7195250-dev",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
