import {
  IonContent,
  IonRow,
  IonCol,
  IonChip,
  IonIcon,
  IonLabel,
  IonList,
  IonListHeader,
  IonProgressBar,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonText,
  IonGrid,
  IonLoading,
  IonItem,
  IonRouterLink,
  IonImg,
  IonCardSubtitle,
} from "@ionic/react";
import { checkmarkCircle, cart, timeOutline } from "ionicons/icons";
import React, { useEffect } from "react";
import "./VideosView.scss";
import axios from "axios";
import { useStore } from "react-redux";
import VideoCarroussel from "../../components/VideoCarroussel";

const Page: React.FC = () => {
  const state = useStore().getState();
  let vids: any = [];
  let videoListUrl;
  let videoCount = 0;
  let endpoint = state.endpoint;
  const endpointView =
    endpoint + "/handlevideoview" + "?user_aws_id=" + state.aws_id;
  if (!state.premium.boulangerie && !state.premium.patisserie) {
    // L'utilisateur n'a pas d'accés donc on utilise l'api free
    endpoint += "/listVideoFree";
  } else {
    endpoint +=
      "/handleVideoObject?boulangerie=" +
      state.premium.boulangerie +
      "&patisserie=" +
      state.premium.patisserie +
      "&user_aws_id=" +
      state.aws_id;
  }
  interface StringMap {
    [key: string]: [];
  }
  let chier: StringMap = {};
  let [promiseResolved, setPromiseResolved] = React.useState(false);
  let [vidByTheme, setVidByTheme] = React.useState(chier);
  let [countToDisplay, setCountToDisplay] = React.useState<number>(10);
  let [countViewed, setCountViewed] = React.useState<number>(0);
  let [countTotal, setCountTotal] = React.useState<number>(1);
  let [resumeVid, setResumeVid] = React.useState<any>();

  useEffect(() => {
    if (promiseResolved) {
      return;
    }
    setPromiseResolved(true);
    axios({
      method: "get",
      url: endpoint,
      headers: { "x-api-key": state.apiKey },
    }).then((response) => {
      let vidthemes: any = {};
      let viewed = 0;
      let lastVid;
      let urls: any = [];
      let total = 0;
      for (let vid of response.data) {
        if (urls[vid["thumb_url"] + vid.section]) {
          continue;
        }
        urls[vid["thumb_url"] + vid.section] = true;
        total++;
        if (vidthemes[vid.section] == undefined) {
          vidthemes[vid.section] = [];
        }
        if (vid["user_aws_id"]) {
          viewed++;
        }
        if (
          !lastVid ||
          (!lastVid["on"] && vid["on"]) ||
          Date.parse(lastVid["on"]) < Date.parse(vid["on"])
        ) {
          lastVid = vid;
        }

        vidthemes[vid.section].push(vid);
      }
      setVidByTheme(vidthemes);
      setCountViewed(viewed);
      setCountTotal(total);
      setResumeVid(lastVid);

      return vidByTheme;
    });
  });
  if (promiseResolved) {
    const totalDisplayed = 10;
    let nbDisplayed = 0;
    for (let theme in vidByTheme) {
      const videlmt: any = vidByTheme[theme];
      let vid = videlmt[0];
      videoCount += Object.keys(videlmt).length;
      if (vid && nbDisplayed < totalDisplayed) {
        const url = vid.thumb_url;
        const title = vid.title;
        nbDisplayed++;
        vids.push(
          <IonCard key={theme} href={videoListUrl}>
            <IonImg src={url} />
            <IonCardHeader>
              <IonCardSubtitle>
                {theme} : {title}
              </IonCardSubtitle>
            </IonCardHeader>
          </IonCard>
        );
      }
    }
  }
  const percentView = countViewed / countTotal;
  let themes = [];
  let resume = <span></span>;
  if (promiseResolved) {
    let i = 0;
    for (let theme in vidByTheme) {
      if (i >= countToDisplay) {
        break;
      }
      const videlmt = vidByTheme[theme];
      themes.push(
        <VideoCarroussel
          videos={videlmt}
          key={theme}
          theme={theme}
        ></VideoCarroussel>
      );
      i++;
    }
    if (resumeVid) {
      resume = (
        <IonGrid>
          <div className="video-resume">
            <h5>Reprendre</h5>
            <IonCard>
              <IonRow>
                <IonCol size="2"></IonCol>
                <IonCol size="8">
                  <IonCardHeader>
                    <video
                      width="100%"
                      poster={resumeVid.thumb_url}
                      controls
                      controlsList="nodownload"
                    >
                      <source
                        src={resumeVid.video_url}
                        type="video/mp4"
                      ></source>
                    </video>
                  </IonCardHeader>
                </IonCol>
                {/* <IonCol size="3">
                  <IonCol>{vids}</IonCol>
                </IonCol> */}
              </IonRow>

              <IonCardContent>
                <b>{resumeVid.title}</b>
                <IonRow className="ion-align-items-center video-time">
                  <IonCol class="no-padding-left" size="auto">
                    <IonIcon color="medium" icon={timeOutline}></IonIcon>
                  </IonCol>
                  <IonCol class="no-padding-left">
                    <IonText className="text-md" color="medium">
                      {Math.floor(parseInt(resumeVid.duration) / 60)}:
                      {String(parseInt(resumeVid.duration) % 60).padStart(
                        2,
                        "0"
                      )}
                    </IonText>
                  </IonCol>
                </IonRow>
              </IonCardContent>
            </IonCard>
          </div>
        </IonGrid>
      );
    }
  }

  function getChip(props: any) {
    if (props.hasAccess) {
      return (
        <IonChip>
          <IonIcon color="primary" icon={checkmarkCircle}></IonIcon>
          <IonLabel>{props.text}</IonLabel>
        </IonChip>
      );
    }
    return (
      <IonRouterLink routerLink="/page/Premium">
        <IonChip>
          <IonIcon color="primary" icon={cart}></IonIcon>
          <IonLabel>Passer au pack premium</IonLabel>
        </IonChip>
      </IonRouterLink>
    );
  }
  const boulangerieChip = getChip({
    hasAccess: state.premium.boulangerie,
    text: "Boulangerie",
  });
  const patisserieChip = getChip({
    hasAccess: state.premium.patisserie,
    text: "Pâtisserie",
  });
  return (
    <IonContent
      class="page-videos"
      scrollEvents={true}
      onIonScrollEnd={(e) => {
        setCountToDisplay(countToDisplay + 10);
      }}
    >
      <IonGrid>
        <div className="block-chips">
          {boulangerieChip}
          {patisserieChip}
        </div>

        <IonRow className="total-progress">
          <IonCol>
            <IonList>
              <IonListHeader>
                <IonLabel className="text-sm">
                  <b>
                    Vous avez consulté {Math.ceil(percentView * 100)}% du
                    contenu vidéo
                  </b>
                </IonLabel>
              </IonListHeader>
              <IonProgressBar
                color="primary"
                value={percentView}
              ></IonProgressBar>
            </IonList>
          </IonCol>
        </IonRow>
        {resume}
      </IonGrid>

      <IonRow>
        <IonCol class="ion-padding-horizontal"></IonCol>
      </IonRow>

      <IonList className="list-videos" lines="full">
        <IonLoading
          isOpen={!(countTotal > 1)}
          message={"Chargement en cours..."}
          spinner="crescent"
        />
        {themes}
      </IonList>
    </IonContent>
  );
};

export default Page;
