import {
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonImg,
  IonText,
  IonList,
  IonItem,
  IonAvatar,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
} from "@ionic/react";
import {
  personOutline,
  lockClosedOutline,
  createOutline,
  starOutline,
  heartOutline,
  documentOutline,
  powerOutline,
  chevronForwardOutline,
  addOutline,
} from "ionicons/icons";
import React, { useState } from "react";
import { useParams } from "react-router";
import "./AccountView.scss";
import { useStore } from "react-redux";
import { Md5 } from "md5-typescript";
/* AWS amplify */
import { AmplifySignOut, AmplifyAuthenticator } from "@aws-amplify/ui-react";
import { Auth } from "aws-amplify";

const Page: React.FC = () => {
  const { name } = useParams<{ name: string }>();
  const state = useStore().getState();
  // const gravatar = "https://gravatar.com/avatar/" + Md5.init(state.email);

  function logout() {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        Auth.signOut({ global: true });
      })
      .catch((err) => {});
  }

  return (
    <IonContent className="page-account">
      <div className="background-container">
        <IonImg className="background-cover" src="assets/tmp/acccount.png" />
      </div>
      {/* <IonRow className="block-avatar ion-justify-content-center">
        <IonCol class="avatar ion-no-padding" size="auto">
          <IonAvatar>
            <IonImg src={gravatar} />
          </IonAvatar>
          <div className="add-avatar">
            <div>
              <IonIcon icon={addOutline} />
            </div>
          </div>
        </IonCol>
      </IonRow> */}

      <div className="user-name ion-text-center">
        <IonText className="text-xxl">
          Bienvenue {state.name} {state.family_name}
        </IonText>
      </div>

      <div className="ion-text-center ion-padding-top">
        <IonText className="text-xl">
          Gérez vos informations, ainsi que la confidentialité et la sécurité de
          vos données sur Formaceo.
        </IonText>
      </div>

      <IonGrid>
        <IonRow className="ion-align-self-center ion-padding">
          <IonCol>
            <IonCard>
              <IonCardHeader>
                <IonCardTitle>Mes informations</IonCardTitle>
                <IonItem href="/page/Infos" detail={true} lines="none">
                  <IonCardContent className="ion-padding-top">
                    Consultez les données de votre compte Formaceo et
                    sélectionnez les informations à enregistrer pour
                    personnaliser votre expérience Formaceo.
                  </IonCardContent>
                </IonItem>
              </IonCardHeader>
            </IonCard>
          </IonCol>

          <IonCol>
            <IonCard>
              <IonCardHeader>
                <IonCardTitle>Modifier mon mot de passe</IonCardTitle>
                <IonItem href="/page/Password" detail={true} lines="none">
                  <IonCardContent className="ion-padding-top">
                    Modifiez votre mot de passe afin de sécuriser au maximum
                    votre compte et d'éviter qu'un tiers se connecte sur votre
                    compte Formaceo .
                  </IonCardContent>
                </IonItem>
              </IonCardHeader>
            </IonCard>
          </IonCol>
        </IonRow>

        {/* SECOND ROW */}
        <IonRow className="ion-align-self-center ion-padding">
          <IonCol>
            <IonCard>
              <IonCardHeader>
                <IonCardTitle>
                  Conditions Générales d'Utilisation et RGPD
                </IonCardTitle>
                <IonItem href="/page/Cgurgpd" detail={true} lines="none">
                  <IonCardContent className="ion-padding-top">
                    Retrouvez ici les Conditions Générales d'Utilisation et les
                    Mentions Légales de Formaceo. Découvrez également comment
                    nous utilisons les cookies dans le respect de la RPGD.
                  </IonCardContent>
                </IonItem>
              </IonCardHeader>
            </IonCard>
          </IonCol>

          <IonCol>
            <IonCard className="premium-content">
              <IonCardHeader>
                <IonCardTitle color="primary">
                  Passer à Formaceo Premium
                </IonCardTitle>
                <IonItem href="/page/Premium" detail={true} lines="none">
                  <IonCardContent className="ion-padding-top ">
                    Passez votre abonnement à Formaceo Premium et profitez du
                    contenu Boulangerie & Pâtisserie de Formaceo dans un
                    abonnement d'une durée de 36 mois !
                  </IonCardContent>
                </IonItem>
              </IonCardHeader>
            </IonCard>
          </IonCol>
        </IonRow>

        <IonRow className="logout-card">
          <IonCol>
            <IonItem
              detail={false}
              onClick={(e) => {
                logout();
              }}
            >
              <IonCard>
                <IonCardHeader>
                  <IonRow className="ion-margin next-to-each-other">
                    <IonIcon color="primary" icon={powerOutline} />
                    <IonCardTitle>Se déconnecter</IonCardTitle>
                  </IonRow>
                  <IonCardContent className="ion-padding margin-auto ion-text-center">
                    Se déconnecter de Formaceo.
                  </IonCardContent>
                </IonCardHeader>
              </IonCard>
            </IonItem>
          </IonCol>
        </IonRow>

        <IonList className="account-links">
          {/* <IonItem>
              <IonRow>
                <IonCol size="auto">
                  <IonIcon color="primary" icon={createOutline} />
                </IonCol>
                <IonCol>
                  <IonText className="text-md">
                    <b>Carnet de recettes</b>
                  </IonText>
                </IonCol>
                <IonCol size="auto">
                  <IonIcon color="primary" icon={chevronForwardOutline} />
                </IonCol>
              </IonRow>
            </IonItem> */}

          {/* <IonItem>
              <IonRow>
                <IonCol size="auto">
                  <IonIcon color="primary" icon={starOutline} />
                </IonCol>
                <IonCol>
                  <IonText className="text-md">
                    <b>Mon abonnement</b>
                  </IonText>
                </IonCol>
                <IonCol size="auto">
                  <IonIcon color="primary" icon={chevronForwardOutline} />
                </IonCol>
              </IonRow>
            </IonItem> */}

          {/* <IonItem href="/page/Notifications" detail={false}>
              <IonRow>
                <IonCol size="auto">
                  <IonIcon color="primary" icon={heartOutline} />
                </IonCol>
                <IonCol>
                  <IonText className="text-md">
                    <b>Gestion des notifications</b>
                  </IonText>
                </IonCol>
                <IonCol size="auto">
                  <IonIcon color="primary" icon={chevronForwardOutline} />
                </IonCol>
              </IonRow>
            </IonItem> */}
        </IonList>
      </IonGrid>
    </IonContent>
  );
};

export default Page;
